const URL = 'https://admin.shipaitu.cn/';
const tools = {
	
	S: {
		getUrl(){
			return URL
		},
		set(key, value) {
			localStorage.setItem(key, JSON.stringify(value));
		},
		sets(key, value) {
			sessionStorage.setItem(key, JSON.stringify(value));
		},
		get(key) {
			return JSON.parse(localStorage.getItem(key));
		},
		gets(key) {
			return JSON.parse(sessionStorage.getItem(key));
		},
		remove(key) {
			localStorage.removeItem(key);
		}
	}
}


export default tools;
